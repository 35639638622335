<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Counter</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <template v-if="pageReady">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-4">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                User *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrluser" v-model.trim="formData.user"  label="User" type="text" placeholder="Enter User"      
                                                                class=" w-full" :class="getErrorClass('user')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('user')" class="p-error">{{ getFieldError('user') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Ref *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlref" v-model.trim="formData.ref"  label="Ref" type="text" placeholder="Enter Ref"      
                                                                class=" w-full" :class="getErrorClass('ref')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('ref')" class="p-error">{{ getFieldError('ref') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Logdate *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrllogdate" v-model.trim="formData.logdate"  label="Logdate" type="text" placeholder="Enter Logdate"    readonly  
                                                                class=" w-full" :class="getErrorClass('logdate')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('logdate')" class="p-error">{{ getFieldError('logdate') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useAuth } from '@/composables/auth';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'counter',
		},
		routeName: {
			type: String,
			default: 'counteredit',
		},
		pagePath: {
			type : String,
			default : 'counter/edit',
		},
		apiPath: {
			type: String,
			default: 'counter/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		msgAfterUpdate: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const auth = useAuth();
	const formDefaultValues = {
		user: auth.userName, 
		ref: "", 
		logdate: utils.dateTimeNow(), 
	}
	const formData = reactive({ ...formDefaultValues });
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterUpdate);
		if(props.redirect) app.navigateTo(`/counter`);
	}
	const rules = computed(() => {
		return {
			user: { required },
			ref: { required },
			logdate: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage(props, formData, v$, onFormSubmited);
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		loading, // form data loading state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { 
		load, // get editable form data from api
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Counter";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
